<template>
  <portal
    target-el="#fp-alert"
    target-class="f-bs4"
  >
    <!-- <campaign
      v-if="is.after('2024/11/19')
        && is.before('2025/01/01')"
    /> -->

    <!--<defisc
      v-if="!busy
        && is.after(`${$t('year.current')}/12/19`)
        && !is.after(`${$t('year.current')}/12/31`)
        && !/soutenir/.test($t('site'))
        && $t('lang') === 'fr'"
    /> -->

    <!-- <dorlotons
      v-if="is.after('2024/6/13')
        && is.before('2024/7/10')"
    /> -->

    <info />

    <!-- <greve
      v-if="is.after('2023/4/19') && is.before('2023/4/21') && $t('lang') === 'fr'"
    /> -->
  </portal>
</template>

<script>

// import Campaign from './Campaign.vue';
// import Defisc from './Defisc.vue';
// import Dorlotons from './Dorlotons.vue';
import Info from './Info.vue';
// import Greve from './Greve.vue';

export default {
  components: {
    // Campaign,
    // Defisc,
    // Dorlotons,
    Info,
    // Greve,
  },

  data() {
    let active = 1;
    const blog = [
      { date: '2023/11/09' }, // coincoin
      { date: '2023/11/22' }, // space
      { date: '2023/11/28' }, // ptv6
      { date: '2023/12/05' }, // mbz
      { date: '2023/12/12' }, // pt 2024
      { date: '2023/12/19' }, // emancipasso
      { date: '2023/12/26' }, // libre
      { date: '2023/12/30' }, // dio
    ];
    for (let i = 0; i < blog.length; i += 1) {
      if (this.is.after(blog[i].date)) {
        active = i + 1;
      }
    }
    return {
      active,
      busy: false,
    };
  },

  created() {
    document.querySelector('#f-nav')
      .insertAdjacentHTML('beforebegin', '<div id="fp-alert"></div>');
    document.body.classList.add(`w-0${this.active}`);
  },
};
</script>

<style lang="scss">
#f-- #fp-alert {
  font-family: 'Roboto', sans-serif;

  // hack to avoid margin collapse
  padding: 1px 0;
  margin: -1px auto;

  /* Display msg only when CSS is ready */
  > div[style="display: none;"] {
    display: block !important;
  }

  .alert {
    font-size:14px;
    line-height:20px;
    margin: 10px auto;
    max-width: 720px;
    text-shadow:none;

    p {
      margin-bottom:5px;
      margin-top:0px;
    }

    a:not(.btn) {
      text-decoration: none;
      border: none;
      color: inherit;
      background-image:
        linear-gradient(var(--f-o5),var(--f-o5)),
        linear-gradient(var(--f-g5),var(--f-g5));
      background-size: 0% 2px, 100% 2px;
      background-position: center bottom, center bottom;
      transition: background-size 0.25s ease-in-out;
      background-repeat: no-repeat;
      padding-top: 2px;
      padding-bottom: 2px;

      &:hover, &:focus {
        text-decoration: none;
        background-size: 100% 2px;
      }
    }

    a {
      font-weight:inherit !important;
    }
  }

  .alert-dismissible .close {
    right: 0px;
    margin-right: 5px;
    margin-top: -5px;

    @media (max-width:800px) {
      right: 3rem;

    }
  }

  .clearfix {
    height:auto !important;
    overflow:visible !important;
  }
}

@media (max-width:576px) {
  #f-- #fp-alert {
    .alert {
      border-top:0px;
      border-left:0px;
      border-right:0px;
      border-radius:0 !important;
      margin-top: 0;
    }
  }
}

/* Sites customisations */
/* ------------------------------------------------------------------ */
[data-url*="https://framasoft.org/"] #home #fp-alert {
  background: linear-gradient(to bottom right,var(--f-f8) 7%,var(--f-f9));
}

[data-url*="https://joinmobilizon.org/"] #home #fp-alert {
  background: var(--j-p2);
}

/* No alert at all */
/* ------------------------------------------------------------------ */
[data-inframe="true"],

[data-url*="framacalc.org/"]:not([data-url*="://accueil"]):not([data-url*="framacalc.org/abc"]), /* Ethercalc */
[data-url*="://framacarte.org"][data-url*="/map/"],                     /* uMap */
[data-url*="://beta.framacarte.org"][data-url*="/map/"],                /* uMap */
[data-url*="://framacolibri.org"],

[data-url*="://contributopia.org"],
[data-url*="://soutenir.degooglisons-internet.org"],

/* Nextcloud */
// [data-url*="://framagenda.org"][data-url*="index.php/app"]:not([data-url*="registration"]),
// [data-url*="://framagenda.org"][data-url*="index.php/setting"],
[data-url*="://framadrive.org"][data-url*="index.php/app"]:not([data-url*="registration"]),
[data-url*="://framadrive.org"][data-url*="index.php/setting"],

/* Etherpad */
[data-url*="://mypads.framapad.org"],                                   /* MyPads */
[data-url*="://mypads2.framapad.org"],                                  /* MyPads2 */
[data-url*=".framapad.org/p/"],
[data-url$=".framapad.org/"],

[data-url*="://wisemapping.framasoft.org/c/maps"][data-url*="/edit"],   /* Wisemapping */
[data-url*="://framindmap.org/c/maps"][data-url*="/edit"],              /* Wisemapping */
[data-url*="://framapiaf.org"]:not([data-url*="/about"]),               /* Mastodon */
[data-url*="://framateam.org"],
[data-url*="://framatube.org"],
[data-url*="://framavox.org"]:not([data-url*="/abc"]),
[data-url*="://framatalk.org"]:not([data-url*="/abc"]),

[data-url*="://status.framasoft.org"],

/* Honkit */
[data-url*="://docs.framasoft.org"],
[data-url*="://framacloud.org"],
[data-url*="://participer.framasoft.org"] {
  #fp-alert {
    display: none !important;
  }
}

/* html:not([data-url*="://soutenir."]) {
  #f-sfs .fa-heart {
    background:
      url('https://soutenir.framasoft.org/img/campaign/2023/UI/selector/01.png')
      no-repeat
      center center;
    background-size: contain;
    border: 5px solid var(--f-o3);
    border-radius: 0.25rem;
    width: 120px;
    height: 120px;
    &::before { visibility: hidden; }
  }

  .w-01 #f-sfs .fa-heart {
    background-image: url('https://soutenir.framasoft.org/img/campaign/2023/UI/selector/01.png');
  }
  .w-02 #f-sfs .fa-heart {
    background-image: url('https://soutenir.framasoft.org/img/campaign/2023/UI/selector/02.png');
  }
  .w-03 #f-sfs .fa-heart {
    background-image: url('https://soutenir.framasoft.org/img/campaign/2023/UI/selector/03.png');
  }
  .w-04 #f-sfs .fa-heart {
    background-image: url('https://soutenir.framasoft.org/img/campaign/2023/UI/selector/04.png');
  }
  .w-05 #f-sfs .fa-heart {
    background-image: url('https://soutenir.framasoft.org/img/campaign/2023/UI/selector/05.png');
  }
  .w-06 #f-sfs .fa-heart {
    background-image: url('https://soutenir.framasoft.org/img/campaign/2023/UI/selector/06.png');
  }
  .w-07 #f-sfs .fa-heart {
    background-image: url('https://soutenir.framasoft.org/img/campaign/2023/UI/selector/07.png');
  }
  .w-08 #f-sfs .fa-heart {
    background-image: url('https://soutenir.framasoft.org/img/campaign/2023/UI/selector/08.png');
  }
} */
</style>

<template>
  <span></span>
</template>

<script>
export default {
  created() {
    // Matomo
    this.analytics(this.$t('site'));
  },

  mounted() {
    // Favicons
    const icons = [{}, {}];

    if (!/(team)/i.test(this.$t('host'))) {
      document.querySelectorAll('link[rel*=icon]').forEach(e => e.parentNode.removeChild(e));
    }

    const regSites = new RegExp([
      '(agenda', 'blog', 'book', 'calc', 'carte', 'cloud', 'colibri', 'date',
      'drive', 'dvd', 'forms', 'games', 'key', 'lab', 'libre',
      'listes', 'memes', 'mindmap', 'petitions', 'piaf', 'space', 'start',
      'stats', 'status', 'talk', 'team', 'tube', 'vox', 'zic',
      'alt.', 'contact.', 'degooglisons-internet', 'forum.', 'participer.',
      'soutenir.', 'wiki.)',
    ].join('|'), 'i');
    if (regSites.test(this.$t('host'))) {
      icons[0].file = `favicon/sites/${this.$t('site')}.png`;
      icons[1].file = `ati/sites/${this.$t('site')}.png`;
    }

    if (/(pad)/i.test(this.$t('host'))) {
      icons[0].file = 'favicon/sites/pad.png';
      icons[1].file = 'ati/sites/pad.png';
    }

    if (!/(team)/i.test(this.$t('host'))) {
      icons[0].file = icons[0].file || 'favicon/violet.png';
      icons[0].link = document.createElement('link');
      Object.assign(icons[0].link, {
        rel: 'icon',
        type: 'image/png',
        href: `${this.$t('baseurl')}img/icons/${icons[0].file}`,
      });
      document.getElementsByTagName('head')[0].appendChild(icons[0].link);

      icons[1].file = icons[1].file || 'ati/orange.png';
      icons[1].link = document.createElement('link');
      Object.assign(icons[1].link, {
        rel: 'apple-touch-icon',
        type: 'image/png',
        href: `${this.$t('baseurl')}img/icons/${icons[1].file}`,
      });
      document.getElementsByTagName('head')[0].appendChild(icons[1].link);
    }

    // RSS
    const rss = document.createElement('link');
    Object.assign(rss, {
      rel: 'alternate',
      type: 'application/rss+xml',
      href: this.$t('link.rss'),
      title: this.$i18n.t('fnav.sites.rss.t1'),
    });
    document.getElementsByTagName('head')[0].appendChild(rss);
  },

  methods: {
    analytics(site) {
      const c = {
        id: '',
        url: 'https://stats.framasoft.org/',
        mode: 'img', // 'js',
      };

      switch (site) {
        case 'soft': /**           */ c.id = '1'; break;
        case 'blog': /**           */ c.id = '3'; break;
        case 'pad': /**            */ c.id = '4'; break;
        case 'etherpad': /**       */ c.id = '4'; break;
        case 'key': /**            */ c.id = '5'; break;
        case 'dvd': /**            */ c.id = '6'; break;
        case 'book': /**           */ c.id = '7'; break;
        // case 'tube': /**           */ c.id = '8'; break;
        case 'zic': /**            */ c.id = '9'; break;
        case 'date': /**           */ c.id = '10'; break;
        case 'calc': /**           */ c.id = '11'; break;
        case 'mindmap': /**        */ c.id = '12'; break;
        case 'soutenir': /**       */ c.id = '18'; break;
        case 'contact': /**        */ c.id = '19'; break;
        case 'start': /**          */ c.id = '23'; break;
        case '10ans': /**          */ c.id = '25'; break;
        case 'games': /**          */ c.id = '28'; break;
        case 'git': /**            */ c.id = '29'; break;
        case 'degooglisons-internet': c.id = '30'; break;
        case 'participer': /**     */ c.id = '33'; break;
        case 'colibri': /**        */ c.id = '33'; break;
        case 'cloud': /**          */ c.id = '35'; break;
        case 'status': /**         */ c.id = '37'; break;
        case 'stats': /**          */ c.id = '39'; break;
        case 'drive': /**          */ c.id = '40'; break;
        case 'carte': /**          */ c.id = '43'; break;
        case 'forms': /**          */ c.id = '44'; break;
        case 'beta.forms': /**     */ c.id = '89'; break;
        case 'petitions': /**      */ c.id = '45'; break;
        case 'vox': /**            */ c.id = '47'; break;
        case 'team': /**           */ c.id = '48'; break;
        case 'talk': /**           */ c.id = '50'; break;
        case 'agenda': /**         */ c.id = '53'; break;
        case 'listes': /**         */ c.id = '54'; break;
        case 'troll': /**          */ c.id = '57'; break;
        case 'docs': /**           */ c.id = '60'; break;
        case 'libre': /**          */ c.id = '61'; break;
        case 'piaf': /**           */ c.id = '62'; break;
        case 'contributopia': /**  */ c.id = '63'; break;
        case 'joinmobilizon': /**  */ c.id = '69'; break;
        case 'mobilizon': /**      */ c.id = '69'; break;
        case 'prout': /**          */ c.id = '78'; break;
        case 'space': /**          */ c.id = '85'; break;
        case 'lab': /**            */ c.id = '16'; break;
        case 'ihm.lab': /**        */ c.id = '92'; break;
        case 'split.lab': /**      */ c.id = '94'; break;
        case 'drawio.lab': /**     */ c.id = '93'; break;
        case 'excalidraw.lab': /** */ c.id = '95'; break;
        case 'signature-pdf.lab':     c.id = '96'; break;
        case 'stirling-pdf.lab':      c.id = '97'; break;
        case 'soutenir.degooglisons-internet': c.id = '91'; break;
        case 'mia': /**            */ c.id = '99'; break;
        case 'lokas': /**         */ c.id = '100'; break;
        case '20ans': /**         */ c.id = '101'; break;
        case 'memes': /**         */ c.id = '103'; break;

        // no default
      }

      if (c.id !== ''
          // Pas de Matomo si DoNotTrack
          // DNT est respecté mais on évite les notifications des uBlock, Ghostery, etc
          && !(navigator.doNotTrack === 'yes'
          || navigator.doNotTrack === '1'
          || navigator.msDoNotTrack === '1'
          || window.doNotTrack === '1')) {
        // Code Javascript
        if (c.mode === 'js') {
          var _paq = window._paq || []; // eslint-disable-line
          _paq.push(['trackPageView']);
          _paq.push(['enableLinkTracking']);
          // Code Matomo JS
          (function matomoJS() {
            const u = c.url;
            _paq.push(['setTrackerUrl', [u, 'p.php'].join('')]);
            _paq.push(['setSiteId', c.id]);
            const d = document;
            const g = d.createElement('script');
            const s = d.getElementsByTagName('script')[0];
            // g.type = 'text/javascript';
            // g.defer = true;
            g.async = true;
            g.src = [u, 'p.js'].join('');
            s.parentNode.insertBefore(g, s);
          }());
        // Code Matomo Image
        } else {
          (function matomoImg() {
            const d = document;
            const g = d.createElement('img');
            const s = d.getElementsByTagName('body')[0];
            g.style = 'position:fixed;border:0;width:1px;height:1px;padding:0;margin:-1px;overflow:hidden;clip:rect(0,0,0,0);';
            g.alt = '';
            g.src = [c.url,
              'p.php?idsite=', c.id,
              '&rec=1',
              '&url=', encodeURIComponent(d.location.href),
              '&urlref=', encodeURIComponent(d.referrer),
            ].join('');
            s.appendChild(g);
          }());
        }
      }
    },
  },
};
</script>
